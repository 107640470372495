import React from 'react';

class SectionList extends React.Component {
  
  render() {
    return (
      <div className="SectionList">
        {this.props.data.map((info, index) => (
            <div key={index} className="inner fade-in">
              <h5 className="skill-name text-white font-secondary mt-4">
                <span className="skill-name-container" dangerouslySetInnerHTML={{__html: info.title}}></span>
              </h5>
              <div dangerouslySetInnerHTML={{__html: info.description}}></div>
            </div>
          ))}
      </div>
    );
  }
}

export default SectionList;