import React from 'react';
import imageThumbNube from '../../images/thumb-web-nube.jpg';
import imageLargeNube from '../../images/large-web-nube.png';
import imageThumbCombos from '../../images/thumb-web-combos.jpg';
import imageLargeCombos from '../../images/large-web-combos.png';
import imageThumbEstudiantes from '../../images/thumb-web-estudiantes.jpg';
import imageLargeEstudiantes from '../../images/large-web-estudiantes.png';
import imageThumbNatura from '../../images/thumb-design-natura.jpg';
import imageLargeNatura from '../../images/large-design-natura.png';
import imageThumbOliva from '../../images/thumb-design-oliva.jpg';
import imageLargeOliva from '../../images/large-design-oliva.png';
import imageThumbFeria from '../../images/thumb-design-feria.jpg';
import imageLargeFeria from '../../images/large-design-feria.png';
import imageThumbPalos from '../../images/thumb-photo-palos.jpg';
import imageLargePalos from '../../images/large-photo-palos.png';
import imageThumbPorto from '../../images/thumb-photo-porto.jpg';
import imageLargePorto from '../../images/large-photo-porto.png';
import imageThumbTroncos from '../../images/thumb-photo-troncos.jpg';
import imageLargeTroncos from '../../images/large-photo-troncos.png';
import imageThumbAbrigo from '../../images/thumb-photo-abrigo.jpg';
import imageLargeAbrigo from '../../images/large-photo-abrigo.png';
import imageThumbDeposito from '../../images/thumb-photo-deposito.jpg';
import imageLargeDeposito from '../../images/large-photo-deposito.png';
import imageThumbArboles from '../../images/thumb-photo-arboles.jpg';
import imageLargeArboles from '../../images/large-photo-arboles.png';
import imageThumbCatedrales from '../../images/thumb-photo-catedrales.jpg';
import imageLargeCatedrales from '../../images/large-photo-catedrales.png';
import imageThumbCups from '../../images/thumb-3d-cups.jpg';
import imageLargeCups from '../../images/large-3d-cups.png';
import imageThumbMinicadena from '../../images/thumb-3d-minicadena.jpg';
import imageLargeMinicadena from '../../images/large-3d-minicadena.png';
import imageThumbTruck from '../../images/thumb-3d-truck.jpg';
import imageLargeTruck from '../../images/large-3d-truck.png';
import imageThumbNotebooks from '../../images/thumb-3d-notebooks.jpg';
import imageLargeNotebooks from '../../images/large-3d-notebooks.png';
import imageThumbBike from '../../images/thumb-3d-bike.jpg';
import imageLargeBike from '../../images/large-3d-bike.png';
import imageThumbMobiles from '../../images/thumb-3d-mobiles.jpg';
import imageLargeMobiles from '../../images/large-3d-mobiles.png';
import imageThumbSalon01 from '../../images/thumb-3d-salon01.jpg';
import imageLargeSalon01 from '../../images/large-3d-salon01.png';
import imageThumbScooter from '../../images/thumb-3d-patinete.jpg';
import imageLargeScooter from '../../images/large-3d-patinete.png';
import imageThumbLoft01 from '../../images/thumb-3d-loft01.jpg';
import imageLargeLoft01 from '../../images/large-3d-loft01.png';
import imageThumbBathroom01 from '../../images/thumb-3d-bathroom01.jpg';
import imageLargeBathroom01 from '../../images/large-3d-bathroom01.png';
import imageThumbSoccer from '../../images/thumb-3d-soccer.jpg';
import imageLargeSoccer from '../../images/large-3d-soccer.png';
import imageThumbBoxy from '../../images/thumb-3d-boxy.jpg';
import imageLargeBoxy from '../../images/large-3d-boxy.png';
import imageThumbMarty from '../../images/thumb-3d-marty.jpg';
import imageLargeMarty from '../../images/large-3d-marty.png';
import imageThumbRyhno from '../../images/thumb-3d-ryhno.jpg';
import imageLargeRyhno from '../../images/large-3d-ryhno.png';
import PortfolioSlider from '../PortfolioSlider/PortfolioSlider.js';
import {withTranslation} from "react-i18next";
import i18n from "../../i18n";

class PortfolioGrid extends React.Component {
  constructor(props) {
    super(props);

    this.web = [
      {
        thumb: imageThumbNube,
        image: imageLargeNube,
        category: 'web',
        info: '2016',
        name: i18n.t('common:portfolioItems.nubeR'),
        isVideo: false,
      },
      {
        thumb: imageThumbCombos,
        image: imageLargeCombos,
        category: 'web',
        info: '2015',
        name: i18n.t('common:portfolioItems.combos'),
        isVideo: false,
      },
      {
        thumb: imageThumbEstudiantes,
        image: imageLargeEstudiantes,
        category: 'web',
        info: '2015',
        name: i18n.t('common:portfolioItems.estudiantes'),
        isVideo: false,
      },
    ];

    this.design = [
      {
        thumb: imageThumbNatura,
        image: imageLargeNatura,
        category: 'design',
        info: i18n.t('common:portfolioItems.design'),
        name: i18n.t('common:portfolioItems.logo'),
        isVideo: false,
      },
      {
        thumb: imageThumbOliva,
        image: imageLargeOliva,
        category: 'design',
        info: i18n.t('common:portfolioItems.design'),
        name: i18n.t('common:portfolioItems.logo'),
        isVideo: false,
      },
      {
        thumb: imageThumbFeria,
        image: imageLargeFeria,
        category: 'design',
        info: i18n.t('common:portfolioItems.design'),
        name: i18n.t('common:portfolioItems.poster'),
        isVideo: false,
      },
    ];

    this.photo = [
      {
        thumb: imageThumbPalos,
        image: imageLargePalos,
        category: 'photo',
        info: i18n.t('common:portfolioItems.blackWhite'),
        name: i18n.t('common:portfolioItems.photo'),
        isVideo: false,
      },
      {
        thumb: imageThumbPorto,
        image: imageLargePorto,
        category: 'photo',
        info: i18n.t('common:portfolioItems.blackWhite'),
        name: i18n.t('common:portfolioItems.photo'),
        isVideo: false,
      },
      {
        thumb: imageThumbTroncos,
        image: imageLargeTroncos,
        category: 'photo',
        info: i18n.t('common:portfolioItems.color'),
        name: i18n.t('common:portfolioItems.photo'),
        isVideo: false,
      },
      {
        thumb: imageThumbAbrigo,
        image: imageLargeAbrigo,
        category: 'photo',
        info: i18n.t('common:portfolioItems.blackWhite'),
        name: i18n.t('common:portfolioItems.photo'),
        isVideo: false,
      },
      {
        thumb: imageThumbDeposito,
        image: imageLargeDeposito,
        category: 'photo',
        info: i18n.t('common:portfolioItems.color'),
        name: i18n.t('common:portfolioItems.photo'),
        isVideo: false,
      },
      {
        thumb: imageThumbArboles,
        image: imageLargeArboles,
        category: 'photo',
        info: i18n.t('common:portfolioItems.color'),
        name: i18n.t('common:portfolioItems.photo'),
        isVideo: false,
      },
      {
        thumb: imageThumbCatedrales,
        image: imageLargeCatedrales,
        category: 'photo',
        info: i18n.t('common:portfolioItems.blackWhite'),
        name: i18n.t('common:portfolioItems.photo'),
        isVideo: false,
      },
    ];

    this.three = [
      {
        thumb: imageThumbCups,
        image: imageLargeCups,
        category: 'three',
        info: i18n.t('common:portfolioItems.cinema4d'),
        name: i18n.t('common:portfolioItems.studio'),
        isVideo: false,
      },
      {
        thumb: imageThumbMinicadena,
        image: imageLargeMinicadena,
        category: 'three',
        info: i18n.t('common:portfolioItems.cinema4d'),
        name: i18n.t('common:portfolioItems.studio'),
        isVideo: false,
      },
      {
        thumb: imageThumbTruck,
        image: imageLargeTruck,
        category: 'three',
        info: i18n.t('common:portfolioItems.cinema4d'),
        name: i18n.t('common:portfolioItems.outdoors'),
        isVideo: false,
      },
      {
        thumb: imageThumbNotebooks,
        image: imageLargeNotebooks,
        category: 'three',
        info: i18n.t('common:portfolioItems.cinema4d'),
        name: i18n.t('common:portfolioItems.studio'),
        isVideo: false,
      },
      {
        thumb: imageThumbBike,
        image: imageLargeBike,
        category: 'three',
        info: i18n.t('common:portfolioItems.cinema4d'),
        name: i18n.t('common:portfolioItems.outdoors'),
        isVideo: false,
      },
      {
        thumb: imageThumbMobiles,
        image: imageLargeMobiles,
        category: 'three',
        info: i18n.t('common:portfolioItems.cinema4d'),
        name: i18n.t('common:portfolioItems.studio'),
        isVideo: false,
      },
      {
        thumb: imageThumbSalon01,
        image: imageLargeSalon01,
        category: 'three',
        info: i18n.t('common:portfolioItems.cinema4d'),
        name: i18n.t('common:portfolioItems.indoor'),
        isVideo: false,
      },
      {
        thumb: imageThumbScooter,
        image: imageLargeScooter,
        category: 'three',
        info: i18n.t('common:portfolioItems.cinema4d'),
        name: i18n.t('common:portfolioItems.studio'),
        isVideo: false,
      },
      {
        thumb: imageThumbLoft01,
        image: imageLargeLoft01,
        category: 'three',
        info: i18n.t('common:portfolioItems.cinema4d'),
        name: i18n.t('common:portfolioItems.indoor'),
        isVideo: false,
      },
      {
        thumb: imageThumbBathroom01,
        image: imageLargeBathroom01,
        category: 'three',
        info: i18n.t('common:portfolioItems.cinema4d'),
        name: i18n.t('common:portfolioItems.indoor'),
        isVideo: false,
      },
      {
        thumb: imageThumbSoccer,
        image: imageLargeSoccer,
        category: 'three',
        info: i18n.t('common:portfolioItems.cinema4d'),
        name: i18n.t('common:portfolioItems.outdoors'),
        isVideo: false,
      },
      {
        thumb: imageThumbBoxy,
        image: imageLargeBoxy,
        category: 'three',
        info: i18n.t('common:portfolioItems.cinema4d'),
        name: i18n.t('common:portfolioItems.boxy'),
        isVideo: true,
      },
      {
        thumb: imageThumbMarty,
        image: imageLargeMarty,
        category: 'three',
        info: i18n.t('common:portfolioItems.cinema4d'),
        name: i18n.t('common:portfolioItems.marty'),
        isVideo: true,
      },
      {
        thumb: imageThumbRyhno,
        image: imageLargeRyhno,
        category: 'three',
        info: i18n.t('common:portfolioItems.cinema4d'),
        name: i18n.t('common:portfolioItems.ryhno'),
        isVideo: true,
      },

      
    ];

    this.launchFilter = (e, id) => {
      var portfolioSlider = document.querySelectorAll('.PortfolioSlider.slider-container');
      var btnFilter = document.querySelectorAll('.btn-filter');

      btnFilter.forEach(function(btn) {
        if(btn.getAttribute('id') !== id) {
          btn.classList.remove('active');
        } else {
          btn.classList.add('active');
        }
      });

      portfolioSlider.forEach(function(slider) {
        if(!slider.classList.contains(id)) {
          slider.classList.remove('active');
        } else {
          slider.classList.add('active');
        }
      });

    }

  }

  render() {
    const { t } = this.props;

    return (
      <div className="PortfolioGrid container-fluid pt-3 pb-2 px-0">
        <div className="container">
          <div className="row">
            <div className="col filters-container mb-5 fade-in">
              <span className="btn-filter web active" id="web" onClick={(e) => this.launchFilter(e, "web")}>{t('portfolioCategories.web')}</span>
              <span className="btn-filter design" id="design" onClick={(e) => this.launchFilter(e, "design")}>{t('portfolioCategories.design')}</span>
              <span className="btn-filter photo" id="photo" onClick={(e) => this.launchFilter(e, "photo")}>{t('portfolioCategories.photo')}</span>
              <span className="btn-filter three" id="three" onClick={(e) => this.launchFilter(e, "three")}>{t('portfolioCategories.three')}</span>
            </div>
          </div>
          <div className="row row-portfolio fade-in">
            <div className="col p-0 px-sm-2">
              <PortfolioSlider dataClass="web active" dataSlider={this.web}/>
              <PortfolioSlider dataClass="design" dataSlider={this.design}/>
              <PortfolioSlider dataClass="photo" dataSlider={this.photo}/>
              <PortfolioSlider dataClass="three" dataSlider={this.three}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(PortfolioGrid);