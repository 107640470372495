import React from 'react';
import './App.scss';
import $ from 'jquery';
import * as THREE from 'three'
import WAVES from 'vanta/dist/vanta.waves.min'
import HeaderMenu from './components/HeaderMenu/HeaderMenu.js';
import HeroSection from './components/HeroSection/HeroSection.js';
import ExperienceSection from './components/ExperienceSection/ExperienceSection.js';
import DevelopmentSection from './components/DevelopmentSection/DevelopmentSection.js';
import ImageSection from './components/ImageSection/ImageSection.js';
import ThreeDSection from './components/ThreeDSection/ThreeDSection.js';
import PortfolioSection from './components/PortfolioSection/PortfolioSection.js';
import FooterSection from './components/FooterSection/FooterSection.js';
import ModalProject from './components/ModalProject/ModalProject.js';
import TransitionSection from './components/TransitionSection/TransitionSection.js';
import * as ScrollMagic from "scrollmagic";
import gsap from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import {withTranslation} from "react-i18next";
import i18n from "./i18n";


ScrollMagicPluginGsap(ScrollMagic, gsap);

class App extends React.Component {

  componentDidMount() {

    // Load page info
    document.title = i18n.t('common:page.title');
    document.getElementsByTagName('meta')["description"].content = i18n.t('common:page.description');
    document.getElementsByTagName('html')[0].setAttribute('lang', i18n.t('common:page.lang'));

    // Background animation
    this.vantaEffect = WAVES({
      el: ".bg-block",
      mouseControls: true,
      touchControls: true,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: 0x0,
      shininess: 17.00,
      waveHeight: 19.50,
      waveSpeed: 0.30,
      THREE: THREE
    })

    // Scrollmagic
    var controller = new ScrollMagic.Controller();

    var scene = new ScrollMagic.Scene({
      triggerElement: ".main-title",
      triggerHook: 0,
      offset: '-150px'
    })
    .setClassToggle(".HeaderMenu", "active")
    .addTo(controller);

    var sceneExperience = new ScrollMagic.Scene({
      triggerElement: ".ExperienceSection",
      triggerHook: 0.9,
    })
    .setClassToggle(".bg-block", "off")
    .addTo(controller);

    // Animations
    gsap.to(".job-card", {
      scrollTrigger: {
        trigger: ".job-card",
        // markers: true,
        start: "bottom 100%",
        toggleActions: "play pause resume reverse"
      },
      duration: 2,
      autoAlpha: 1,
      stagger: 0.2
    });

    gsap.utils.toArray(".fade-in").forEach(fadeElement => {
      gsap.to(fadeElement, {
        scrollTrigger: {
          trigger: fadeElement,
          // markers: true,
          start: "bottom 100%",
          toggleActions: "play pause resume reverse"
        },
        duration: 3,
        opacity: 1,
        x: 0,
      });
    });

    gsap.utils.toArray(".translate-animation").forEach(translateElement => {
      gsap.to(translateElement, {
        scrollTrigger: {
          trigger: translateElement,
          // markers: true,
          start: "bottom 100%",
          toggleActions: "play pause resume reverse"
        },
        duration: 1,
        x: 0,
        y: 0,
      });
    });

    // Smotth scroll
    var html = document.documentElement;
    var body = document.body;

    var scroller = {
      target: document.querySelector(".scroll-container"),
      ease: 0.05, // <= scroll speed
      endY: 0,
      y: 0,
      resizeRequest: 1,
      scrollRequest: 0,
    };

    var requestId = null;

    gsap.set(scroller.target, {
      rotation: 0.01,
      force3D: true
    });

    window.addEventListener("load", onLoad);

    function onLoad() {

      var isIos = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

        if(window.innerWidth > 767 && !isIos) {
          updateScroller();
          window.focus();
          window.addEventListener("resize", onResize);
          document.addEventListener("scroll", onScroll);
        } else {
          document.querySelector('.App').classList.add('noSmoothScroll');
        }
    }

    function updateScroller() {

      var resized = scroller.resizeRequest > 0;

      if (resized) {
        var height = scroller.target.clientHeight;
        body.style.height = height + "px";
        scroller.resizeRequest = 0;
      }

      var scrollY = window.pageYOffset || html.scrollTop || body.scrollTop || 0;

      scroller.endY = scrollY;
      scroller.y += (scrollY - scroller.y) * scroller.ease;

      if (Math.abs(scrollY - scroller.y) < 0.05 || resized) {
        scroller.y = scrollY;
        scroller.scrollRequest = 0;
      }

      gsap.set(scroller.target, {
        y: -scroller.y
      });

      requestId = scroller.scrollRequest > 0 ? requestAnimationFrame(updateScroller) : null;
    }

    function onScroll() {
      scroller.scrollRequest++;
      if (!requestId) {
        requestId = requestAnimationFrame(updateScroller);
      }
    }

    function onResize() {
      scroller.resizeRequest++;
      if (!requestId) {
        requestId = requestAnimationFrame(updateScroller);
      }
    }

    // Smooth scroll on click
    $("span[data-anchor], div[data-anchor]").on('click', function (event) {

      if ($(this).attr("data-anchor") !== "") {
        event.preventDefault();

        var data_anchor = $(this).attr("data-anchor");

        $('html, body').animate({
          scrollTop: $(data_anchor).offset().top - 130
        }, 800);
      }
    });

  }

  render() {
    return (
      <div className="App">
        <div className="bg-block"></div>
        <HeaderMenu />
        <TransitionSection />
        <div className="scroll-container">
          <HeroSection />
          <ExperienceSection />
          <DevelopmentSection />
          <ImageSection />
          <ThreeDSection />
          <PortfolioSection />
          <FooterSection />
        </div>
        <ModalProject />
      </div>
    );
  }
}

// export default App;
export default withTranslation('common')(App);