import React from 'react';
import imageImage from '../../images/imagen.jpg';
import imageImageMobile from '../../images/imagen-mobile.jpg';
import GeneralSkillSection from '../GeneralSkillSection/GeneralSkillSection.js';
import {withTranslation} from "react-i18next";
import i18n from "../../i18n";

class ImageSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      skills: [{
        icon: 'fa',
        title: i18n.t('common:sectionListTiles.skills'),
        description: i18n.t('common:image.skills'),
      },
      {
        icon: 'fa',
        title: i18n.t('common:sectionListTiles.experience'),
        description: i18n.t('common:image.experience'),
      },
      {
        icon: 'fa',
        title: i18n.t('common:sectionListTiles.education'),
        description: i18n.t('common:image.education'),
      },
      ]
    };

  }

  render() {
    const { t } = this.props;

    return (
      <GeneralSkillSection 
                  id="imagen"
                  title={t('image.title')}
                  description={t('image.description')}
                  image={imageImage}
                  imageMobile={imageImageMobile}
                  rowClass="flex-row flex-row-reverse"
                  skills={this.state.skills} />
    );
  }
}

export default withTranslation('common')(ImageSection);