import React from 'react';
import imageDevelopment from '../../images/informatica.jpg';
import imageDevelopmentMobile from '../../images/informatica-mobile.jpg';
import GeneralSkillSection from '../GeneralSkillSection/GeneralSkillSection.js';
import {withTranslation} from "react-i18next";
import i18n from "../../i18n";

class DevelopmentSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      skills: [{
        icon: 'fa',
        title: i18n.t('common:sectionListTiles.skills'),
        description: i18n.t('common:development.skills'),
      },
      {
        icon: 'fa',
        title: i18n.t('common:sectionListTiles.experience'),
        description: i18n.t('common:development.experience'),
      },
      {
        icon: 'fa',
        title: i18n.t('common:sectionListTiles.education'),
        description: i18n.t('common:development.education'),
      },
      ]
    };

  }

  render() {
    const { t } = this.props;

    return (
      <GeneralSkillSection 
                  id="desarrollo"
                  title={t('development.title')}
                  description={t('development.description')}
                  image={imageDevelopment}
                  imageMobile={imageDevelopmentMobile}
                  rowClass="flex-row"
                  skills={this.state.skills} />
    );
  }
}

export default withTranslation('common')(DevelopmentSection);