import React from 'react';

class TransitionSection extends React.Component {

  render() {

    return (
      <div className="TransitionSection"></div>
    );
  }
}

export default TransitionSection;