import React from 'react';
import gsap from "gsap";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

class PortfolioSlider extends React.Component {
  constructor(props) {
    super(props);

    this.handleProjectModal = (thumb, name, info, image) => {
      var tl = gsap.timeline();
      tl.to(".ModalProject", { display: 'block', autoAlpha: 1 , duration: 0.5 }, 0)
      .fromTo(".ModalProject img", { opacity: 0}, { opacity: 1, duration: 1 }, 1)
      .fromTo(".ModalProject .project-info", { opacity: 0, y: -10 }, { opacity: 1, y: 0, duration: 0.5 }, 0.45)
      .fromTo(".ModalProject .project-close", { opacity: 0}, { opacity: 1, duration: 0.5 }, 0.5)
      document.querySelector('.ModalProject .project-name').innerHTML = name;
      document.querySelector('.ModalProject .project-description').innerHTML = info;
      document.querySelector('.ModalProject img').src = image;
    }
  }

  render() {
    return (
      <div className={"PortfolioSlider container slider-container " + this.props.dataClass}>
       
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          watchOverflow={true}
          breakpoints={{ 768: { slidesPerView: 2 }, 992: { slidesPerView: 3 } }}
          navigation
          className={"slider " + this.props.dataClass}
        >
          {this.props.dataSlider.map((item, index) => (
            <SwiperSlide key={index}>
              <div key={index} className="portfolio-item" onClick={ () => this.handleProjectModal(item.thumb, item.name, item.info, item.image)}>
                <div className="inner">
                    <img className="w-100" src={item.thumb} alt={item.name} />
                    {/* <div className="portfolio-item--info-hover">
                      <div className="portfolio-item--info-hover-inner">
                        <h2 className="h5 text-light font-weight-bold">{item.name}</h2>
                        <h4 className="h6 text-light">{item.year}</h4>
                      </div>
                    </div> */}
                  </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

      </div>
    );
  }
}

export default PortfolioSlider;