import React from 'react';
import SectionList from '../SectionList/SectionList.js';

class GeneralSkillSection extends React.Component {

  render() {
    return (
      <div className="GeneralSkillSection container-fluid pt-6 pb-6 pb-lg-10" id={this.props.id}>
        <div className="bg-section fade-in"></div>
        <div className="container">
          <div className={"row " + this.props.rowClass}>
            <div className="col-12 col-lg-6 mb-5 mb-lg-0">
              <h2 className="section-title text-left mb-4 fade-in">{this.props.title}</h2>
              <p className="section-description text-left mb-4 fade-in">{this.props.description}</p>
              <SectionList data={this.props.skills} />
            </div>
            <div className="col-1"></div>
            <div className="col-12 col-lg-5">
              <div className="image-section fade-in">
                  <div className="inner translate-animation">
                    <picture className="w-100">
                      <source media="(max-width: 992px)" srcSet={this.props.imageMobile}/>
                      <source media="(min-width: 993px)" srcSet={this.props.image}/>
                      <img className="w-100" src={this.props.image} alt={this.props.description}/>
                    </picture>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GeneralSkillSection;