import React from 'react';
import logoWhite from '../../images/logo-white.svg';
import gsap from "gsap";
import {withTranslation} from "react-i18next";
import i18n from "../../i18n";

class HeaderMenu extends React.Component {

  constructor(){
    super();

    this.menu = [
      {
        name: i18n.t('common:header.experience'),
        anchor: '#experiencia',
      },
      {
        name: i18n.t('common:header.development'),
        anchor: '#desarrollo',
      },
      {
        name: i18n.t('common:header.image'),
        anchor: '#imagen',
      },
      {
        name: i18n.t('common:header.threeD'),
        anchor: '#three-d',
      },
      {
        name: i18n.t('common:header.portfolio'),
        anchor: '#portfolio',
      },
      {
        name: i18n.t('common:header.info'),
        anchor: '#info',
      },

      ];

    this.handleMobileMenu = () => {
      if (document.body.classList.contains('menu-mobile-opened')) {
        gsap.to(".menu-mobile", {display: 'none', autoAlpha: 0, duration: .5});
        gsap.fromTo(".menu-mobile ul li", {opacity: 1, y: 10}, {opacity: 0, y: 0, stagger: 0.09, duration: 1.5, delay: 0.3});
        document.body.classList.remove('menu-mobile-opened');
        document.querySelector('.menu-mobile-icon .icon').classList.add('icon-menu-open');
        document.querySelector('.menu-mobile-icon .icon').classList.remove('icon-close-thin');
      } else {
        gsap.to(".menu-mobile", {display: 'block', autoAlpha: 1, duration: .5});
        gsap.fromTo(".menu-mobile ul li", {opacity: 0, y: 0}, {opacity: 1, y: 10, stagger: 0.09, duration: 1.5, delay: 0.3});
        document.body.classList.add('menu-mobile-opened');
        document.querySelector('.menu-mobile-icon .icon').classList.remove('icon-menu-open');
        document.querySelector('.menu-mobile-icon .icon').classList.add('icon-close-thin');
      }
    }
  };
 
  render() {
    // const { t } = this.props;

    return (
      <div className="HeaderMenu">
        <div className="HeaderMenuMain px-5 inner">
          <div className="logo-container">
            <span data-anchor="#hero-section">
              <img src={logoWhite} alt="Óscar Seoane" />
            </span>
          </div>
          <div className="menu-container d-none d-md-block">
            <ul>
              {this.menu.map((menuitem, index) => (
                <li key={menuitem.name}><span data-anchor={menuitem.anchor}>{menuitem.name}</span></li>
              ))}
            </ul>
          </div>
          <div className="menu-mobile-icon d-md-none" onClick={this.handleMobileMenu}>
            <span className="h3 icon icon-menu-open"></span>
          </div>
        </div>

        <div className="menu-mobile">
            <ul>
              {this.menu.map((menuitem, index) => (
                <li onClick={this.handleMobileMenu} key={menuitem.name} className="display-4 text-light font-secondary pb-4 font-weight-bold">
                  <span data-anchor={menuitem.anchor}>{menuitem.name}</span>
                </li>
              ))}
            </ul>
        </div>

      </div>
    );
  }
}

// export default HeaderMenu;
export default withTranslation('common')(HeaderMenu);
