import React from 'react';
import $ from 'jquery';
import imageLargeCombos from '../../images/large-web-combos.png';
import * as THREE from 'three';
import CELLS from 'vanta/dist/vanta.cells.min';
import gsap from "gsap";

class ModalProject extends React.Component {
  constructor(props) {
    super(props);

    this.closeProjectModal = () => {
      var tl = gsap.timeline();
      tl.to(".ModalProject", { display: 'none', autoAlpha: 0 , duration: 0.5 }, 0)
    }

    $(document).on('keydown', function(event) {
        if (event.key === "Escape") {
          var tl = gsap.timeline();
          tl.to(".ModalProject", { display: 'none', autoAlpha: 0 , duration: 0.5 }, 0)
        }
    });
  }

  componentDidMount() {
    this.vantaEffect = CELLS({
      el: ".project-bg-row",
      mouseControls: true,
      touchControls: true,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      color1: 0x0,
      color2: 0x222222,
      THREE: THREE
    })

  }

  render() {
    return (
      <div className="ModalProject">
        <div className="project-close p-2" onClick={ () => this.closeProjectModal()}>
          <span className="h5 icon icon-close-thin text-light"></span>
        </div>
        <div className="project-image-container">
          <img src={imageLargeCombos} alt=""/>
        </div>
        <div className="project-info text-left">
            <h3 className="project-name text-light font-weight-bold m-0">Project name</h3> 
            <p className="project-description text-light m-0"></p> 
        </div>
        <div className="project-bg-row"></div>
      </div>
    );
  }
}

export default ModalProject;