import React from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ExperienceSlider from '../ExperienceSlider/ExperienceSlider.js';
import {withTranslation} from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

class ExperienceSection extends React.Component {

  render() {
    const { t } = this.props;

    return (
      <div className="ExperienceSection container-fluid py-5 py-lg-10" id="experiencia">
        <div className="bg-section fade-in"></div>
        <div className="ExperienceSection container">
          <h2 className="section-title fade-in">{t('experience.title')}</h2>
          <div className="w-100 pb-6"></div>
          <ExperienceSlider />
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(ExperienceSection);