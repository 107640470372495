import React from 'react';
import {withTranslation} from "react-i18next";
import { withRouter } from "react-router";

class LanguageSwitcher extends React.Component {

  constructor(props){
    super(props);

    this.changeLanguage = (lng) => {
      document.querySelector('.TransitionSection').classList.add('active');
      
      setTimeout(function(){ 
        props.history.push({
          pathname: '/',
          search: `?lng=${lng}`,
        });
        window.location.reload(false);
      }, 300);
    }

  };
 
  render() {
    const { t } = this.props;

    return (
      <div className="LanguageSwitcher">
          <span className="lang text-light small px-1" onClick={() => this.changeLanguage('es')}>{t('languages.spanish')}</span>
          <span className="lang text-light small px-1" onClick={() => this.changeLanguage('en')}>{t('languages.english')}</span>
      </div>
    );
  }
}

export default withTranslation('common')(withRouter(LanguageSwitcher));
