import React from 'react';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher.js';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import logoPhotoshop from '../../images/logo-photoshop-white.png';
import logoIllustrator from '../../images/logo-illustrator-white.png';
import logoInDesign from '../../images/logo-indesign-white.png';
import logoMaya from '../../images/logo-maya-white.png';
import logoCinema4D from '../../images/logo-cinema4d-white.png';
import logoReactWhite from '../../images/logo-react-white.svg';
import {withTranslation} from "react-i18next";

class FooterSection extends React.Component {

  constructor(){
    super();

    this.tutorials = [
      {
        name: 'Photoshop',
        logo: logoPhotoshop,
        link: "https://www.youtube.com/playlist?list=PLxjQN1N94jCgWRl7I7lIKaeIVgaCM7h-l"
      },
      {
        name: 'Illustrator',
        logo: logoIllustrator,
        link: "https://www.youtube.com/playlist?list=PLxjQN1N94jCjOYUVQzB3z-06u1QIiIlxZ"
      },
      {
        name: 'InDesign',
        logo: logoInDesign,
        link: "https://www.youtube.com/playlist?list=PLxjQN1N94jCjFf2pST4yHUxFBxIjLnSWg"
      },
      {
        name: 'Maya',
        logo: logoMaya,
        link: "https://www.youtube.com/playlist?list=PLxjQN1N94jCh8_X9QA_h9Y4wt94O01RXO"
      },
      {
        name: 'Cinema 4D',
        logo: logoCinema4D,
        link: "https://www.youtube.com/playlist?list=PLxjQN1N94jChvHKKRbhenLLAN8CZfOSta"
      },
      ]
    ;

    this.social = [
      {
        name: 'LinkedIn',
        logo: 'icon-linkedin2',
        link: "https://www.linkedin.com/in/oscar-seoane-yanez/"
      },
      {
        name: 'Skype',
        logo: 'icon-skype',
        link: "skype:seoane_oscar?call"
      },
      ]
  };
  
  render() {
    const { t } = this.props;

    const initialYear = "2020";
    const currentYear = new Date().getFullYear();

    return (
      <div className="FooterSection pt-5 pt-md-7 pb-3" id="info">
        <div className="container-fluid">
          <div className="container">
            <div className="row pb-5 pb-md-8">
              <div className="col-12 col-md-5 offset-md-1 pb-5 pb-md-0">
                <h2 className="h2 text-light font-secondary fade-in">{t('footer.tutorials')}</h2>
                <div className="tutorials-container fade-in">
                  {this.tutorials.map((tutorial, index) => (
                    <OverlayTrigger
                      key={index}
                      placement='bottom'
                      overlay={
                        <Tooltip id='bottom'>
                          {tutorial.name}
                        </Tooltip>
                      }
                      >
                      <a href={tutorial.link} target="_blank" rel="noopener noreferrer">
                        <img className="w-100" src={tutorial.logo} alt={tutorial.name} />
                        </a>
                    </OverlayTrigger>
                  ))}
                </div>
              </div>
              <div className="col-12 col-md-5">
                <h2 className="h2 text-light font-secondary fade-in">{t('footer.contact')}</h2>
                <div className="social-container fade-in">
                  {this.social.map((socialitem, index) => (
                    <OverlayTrigger
                      key={index}
                      placement='bottom'
                      overlay={
                        <Tooltip id='bottom'>
                          {socialitem.name}
                        </Tooltip>
                      }
                      >
                      <a className="icon-social text-decoration-none" href={socialitem.link} target="_blank" rel="noopener noreferrer">
                        <span className={"text-light icon " + socialitem.logo}></span>
                      </a>
                    </OverlayTrigger>
                  ))}
                </div>
              </div>
            </div>
            <div className="row pb-3">
              <div className="col">
                <img className="react-footer-logo mb-3" src={logoReactWhite} alt="React" />
                <span className="text-light d-block small">{t('footer.copyright', { initialYear: initialYear, currentYear: currentYear })}</span>
                <LanguageSwitcher />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(FooterSection);