import React from 'react';
import {gsap, Expo} from "gsap";
import {withTranslation} from "react-i18next";

class HeroSection extends React.Component {

  yearsDiff = (d1, d2) => {
      const date1 = new Date(d1);
      const date2 = new Date(d2);
      const yearsDiff =  date2.getFullYear() - date1.getFullYear();
      return yearsDiff;
  }

  componentDidMount() {

    var textWrapper = document.querySelector('.HeroSection .center-block .main-title');
    textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    var tl = gsap.timeline();
    tl.fromTo(".bg-block", { opacity: 0 }, { opacity: 1, duration: 3 }, 0)
    .fromTo(".HeroSection .main-title-block", { opacity: 0 }, { opacity: 1, duration: 1 }, 0.75)
    .staggerFrom(".HeroSection .center-block .main-title .letter", 2, { opacity: 0, rotationY: 90, ease: Expo.easeOut }, 0.06, 0.75)
    .fromTo(".HeroSection .scroll-downs", { opacity: 0 }, { opacity: 1, duration: 1 }, 1.2)
    .fromTo(".HeaderMenu", { opacity: 0 }, { opacity: 1, duration: 1 }, 1)
    .fromTo(".HeaderMenu .logo-container", { opacity: 0, y: -20 }, { opacity: 1, y: 0, duration: 1 }, 1)
    .fromTo(".HeaderMenu .menu-container", { opacity: 0, y: -20 }, { opacity: 1, y: 0, duration: 1 }, 1)
    .fromTo(".HeroSection .center-block h3", { opacity: 0, y: 20 }, { opacity: 1, y: 0, duration: 1 }, 1)
  }

  render() {
    const { t } = this.props;

    const initialDate = new Date('2014-04-15');
    const yearOfExperience = this.yearsDiff(initialDate, Date.now());

    return (
      <div className="HeroSection" id="hero-section">
        <div className="center-block text-center w-100 px-4">
          <div className="main-title-block font-weight-bold">
            <div className="inner">
              <h1 className="main-title font-secondary font-weight-bold">{t('hero.title')}</h1>
            </div>
          </div>
          <h3 className="main-subtitle font-secondary font-weight-light d-block mt-4 mt-sm-5" dangerouslySetInnerHTML={{__html: t('hero.subtitle', { years: yearOfExperience })}}></h3>
        </div>
        <div className="scroll-downs" data-anchor="#experiencia">
          <div className="mousey">
          <div className="scroller"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(HeroSection);