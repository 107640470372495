import i18next from 'i18next';
import common_es from "./translations/es/common.json";
import common_en from "./translations/en/common.json";
import LngDetector from 'i18next-browser-languagedetector';

i18next
  .use(LngDetector)
  // .use(backend)
  .init({
    // detection: {
    //   order: ['path', 'localStorage', 'querystring', 'htmlTag', 'cookie', 'sessionStorage', 'navigator', 'subdomain'],
    // },
    interpolation: {
      escapeValue: false,
    },
    // lng:  'es',  
    // fallbackLng : "es",
    resources: {
      es: {
          common: common_es
      },
      en: {
          common: common_en
      },
    },
  })

export default i18next