import React from 'react';
import logoR from '../../images/logo-r-white.png';
import logoZH from '../../images/logo-zh-white.png';
import logoCivica from '../../images/logo-civica-white.png';
import logoBysidecar from '../../images/logo-bysidecar-white.png';
import logoCinfo from '../../images/logo-cinfo-white.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import {withTranslation} from "react-i18next";
import i18n from "../../i18n";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
gsap.registerPlugin(ScrollTrigger);

class ExperienceSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jobs: [{
        id: 'civica',
        year: `2020-${i18n.t('common:general.present')}`,
        enterprise: "Cívica Software",
        image: logoCivica,
        job: i18n.t('common:experience.desarrolladorWeb')
      },{
        id: 'bysidecar',
        year: "2019-2020",
        enterprise: "Bysidecar",
        image: logoBysidecar,
        job: i18n.t('common:experience.desarrolladorWeb')
      },
      {
        id: 'zarahome',
        year: "2016-2019",
        enterprise: "Zara Home",
        image: logoZH,
        job: i18n.t('common:experience.desarrolladorWeb')
      },
      {
        id: 'cinfo',
        year: "2015-2016",
        enterprise: "Cinfo",
        image: logoCinfo,
        job: i18n.t('common:experience.desarrolladorWeb')
      },
      {
        id: 'r-web',
        year: "2014-2015",
        enterprise: "R Cable",
        image: logoR,
        job: i18n.t('common:experience.desarrolladordisenadorWeb')
      },
      {
        id: 'r-desarrollo',
        year: "2014",
        enterprise: "R Cable",
        image: logoR,
        job: i18n.t('common:experience.desarrolladorApps')
      },

      ]
    };
  }

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide })
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className="ExperienceSlider container">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          breakpoints={{ 768: { slidesPerView: 2 }, 992: { slidesPerView: 3 } }}
          pagination={{ clickable: true, type: 'bullets' }}
          // onSlideChange={() => console.log('slide change')}
        >
          {this.state.jobs.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="job-card">
                <div className="inner d-flex flex-column justify-content-center align-items-center">
                  <h6 className="job-year text-white font-secondary mb-2">{item.year}</h6>
                  <img className="w-100" src={item.image} alt={item.job} />
                  <h5 className="job-name text-white">{item.job}</h5>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

    );
  }
}

export default withTranslation('common')(ExperienceSlider);