import React from 'react';
import PortfolioGrid from '../PortfolioGrid/PortfolioGrid.js';
import {withTranslation} from "react-i18next";

class PortfolioSection extends React.Component {

  render() {
    const { t } = this.props;

    return (

      <div className="PortfolioSection container-fluid py-6" id="portfolio">
        <h2 className="section-title text-center mb-4 fade-in">{t('portfolio.title')}</h2>
        <p className="section-description text-center mb-2 fade-in" dangerouslySetInnerHTML={{__html: t('portfolio.description')}}></p>
        <PortfolioGrid />
      </div>
    
    );
  }
}

export default withTranslation('common')(PortfolioSection);